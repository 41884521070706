import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, chunk } from 'lodash';
import withRouter from 'react-router/withRouter';

import loadable from '@loadable/component';

const NotFound = loadable(() =>
  import('../../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);
const WciLayout = loadable(
  () => import('../../../../fc-components/main/containers'),
  {
    resolveComponent: components => components.WciLayout
  }
);
const Button = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Button
  }
);
const IconTitle = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.IconTitle
  }
);
const Image = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Image
  }
);
const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Text = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Text
  }
);

const WciApplyPage = props => {
  const fullPackageObj = get(props, 'franchisePage', null);
  const packageData = get(fullPackageObj, 'data', {});

  const packageBlurb = get(packageData, 'acf.franchise_blurb', '');
  const acceptingApplications = get(
    packageData,
    'acf.application_page.acceptingApplications',
    undefined
  );
  const packageDeadline = get(
    packageData,
    'acf.application_page.deadline',
    undefined
  );
  const packageApplyUrl = get(
    packageData,
    'acf.application_page.applyUrl',
    '#'
  );
  const packageEligibilityBlurb = get(
    packageData,
    'acf.application_page.eligibility',
    ''
  );
  const packageButtonText = get(
    packageData,
    'acf.application_page.buttonText',
    '#'
  );
  const packageCategories = get(
    packageData,
    'acf.application_page.categories',
    []
  );
  const hideJudgeSection =
    packageData?.acf?.application_page?.hide_judges || false;
  const packageJudges = get(packageData, 'acf.application_page.judges', []);
  const packageWhyApply = get(packageData, 'acf.application_page.whyApply', []);
  const whyApplyColumns = chunk(
    packageWhyApply,
    (packageWhyApply.length + 1) / 2
  );
  const franchiseFeatureImageDesktop = get(
    packageData,
    'acf.franchise_images.franchise_animated_svg_desktop'
  );

  // Initiate from Redux
  const { franchisePage, status } = props;

  // Handle Lazy Loading & Errors
  if (franchisePage.error || status === 404 || isEmpty(fullPackageObj)) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (franchisePage.isLoading) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  return (
    <WciLayout type="Application">
      <Section name="topHero" maxWidth={688} bgColor="#FC136F">
        <div className="topItems">
          <Image image={franchiseFeatureImageDesktop} />
          <Text as="h2" color="#000000">
            {packageDeadline}
          </Text>
          {acceptingApplications && (
            <a href={packageApplyUrl}>
              <Button
                rounded
                bgColor="#ffffff"
                labelColor="#000000"
                labelHoverColor="#ffffff"
                label={packageButtonText}
              />
            </a>
          )}
        </div>
      </Section>

      <Section name="blurb" maxWidth={860} vMargins="20">
        <Text as="div">
          <div dangerouslySetInnerHTML={{ __html: packageBlurb }} />
        </Text>
        {acceptingApplications && (
          <a href={packageApplyUrl}>
            <Button
              bgColor="#FC136F"
              labelHoverColor="#ffffff"
              rounded
              label={packageButtonText}
            />
          </a>
        )}
        <div className="deadlineSubText">
          <Text as="h6" color="#000000">
            {packageDeadline}
          </Text>
        </div>
      </Section>

      <Section name="whyApply" maxWidth={860} bgColor="#000000">
        <div className="sectionHeading">
          <IconTitle
            title="WHY APPLY"
            color="#ffffff"
            underline
            underlineColor="#ffffff"
          />
        </div>
        <div className="reasonContainer">
          {whyApplyColumns.map((column, i) => (
            <div key={`whyApply-column-${i}`} className="whyApply-column">
              {column.map((reason, index) => (
                <div key={`whyApply-${index}`} className="whyApplyItem">
                  <div className="icon">
                    <Image image={get(reason, 'icon.url')} />
                  </div>
                  <div>
                    <Text as="h3" color="#ffffff">
                      {reason.title}
                    </Text>
                    <Text
                      fontFamily="'CentraNo2Book', sans-serif"
                      fontSize="15px"
                      lineHeight="17px"
                      as="div"
                      color="#cccccc"
                    >
                      {reason.description}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Section>

      <Section maxWidth={860} vMargins="20">
        <div className="sectionHeading">
          <IconTitle title="ELIGIBILITY" underline />
        </div>
        <Text as="div">
          <div dangerouslySetInnerHTML={{ __html: packageEligibilityBlurb }} />
        </Text>
        {acceptingApplications && (
          <a href={packageApplyUrl}>
            <Button
              bgColor="#FC136F"
              labelHoverColor="#ffffff"
              rounded
              label={packageButtonText}
            />
          </a>
        )}
        <div className="deadlineSubText">
          <Text as="h6" color="#000000">
            {packageDeadline}
          </Text>
        </div>
      </Section>

      <Section name="categories" maxWidth={860}>
        <div className="sectionHeading">
          <IconTitle title="CATEGORIES" underline />
        </div>
        <div>
          {packageCategories.map((category, index) => {
            const categoryGroup = get(category, 'categoryGroup', {});
            const categoryName = get(categoryGroup, 'name', ''); // eslint-disable-line
            const categoryItems = get(categoryGroup, 'items', []);

            return (
              <div
                key={`category-container-${index}`}
                className="categoryContainer"
              >
                {categoryItems.map((item, i) => (
                  <div key={`category-item-${i}`}>
                    <Text as="h3">
                      {item.title}{' '}
                      {item.subHed ? <span>{item.subHed}</span> : ''}
                    </Text>
                    <Text
                      fontFamily="'CentraNo2Book', sans-serif"
                      fontSize="15px"
                      lineHeight="17px"
                      as="div"
                      color="#5f5f5f"
                    >
                      {item.description}
                    </Text>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </Section>

      {hideJudgeSection && (
        <Section maxWidth={860}>
          <div className="sectionHeading">
            <IconTitle title="PAST JUDGES" underline />
          </div>
          <div className="judgesContainer">
            {packageJudges.map((judge, i) => (
              <div key={`judge-${i}`} className="judge-item">
                <Image image={judge.image.url} />
                <div className="judge-item__name">
                  <Text
                    as="div"
                    fontFamily="'CentraNo2', sans-serif"
                    fontWeight="700"
                    fontSize="22px"
                  >
                    {judge.name}
                  </Text>
                </div>
                <div className="judge-item__title">
                  <Text
                    as="div"
                    fontFamily="'CentraNo2Book', sans-serif"
                    color="#5f5f5f"
                    fontSize="15px"
                    lineHeight="17px"
                  >
                    {judge.title}
                  </Text>
                </div>
                <div className="judge-item__company">
                  <Text
                    as="div"
                    fontFamily="'CentraNo2', sans-serif"
                    fontWeight="700"
                    lineHeight="17px"
                    color="#5f5f5f"
                    fontSize="15px"
                  >
                    {judge.company}
                  </Text>
                </div>
              </div>
            ))}
          </div>
        </Section>
      )}
      <Section name="finalApply" maxWidth={860}>
        {acceptingApplications && (
          <a href={packageApplyUrl}>
            <Button
              bgColor="#FC136F"
              labelHoverColor="#ffffff"
              rounded
              label={packageButtonText}
            />
          </a>
        )}
        <div className="deadlineSubText">
          <Text as="h6" color="#000000">
            {packageDeadline}
          </Text>
        </div>
      </Section>
    </WciLayout>
  );
};

// SSR Loading of data via Redux
// WorldChangingIdeasPage.loadData = store => loadData(store);

// function mapStateToProps(state = {}) {
//   return {
//     status: state.status.code,
//     worldChangingIdeasPage: get(state, 'worldChangingIdeasPage') || {
//       error: true
//     },
//     config: state.config
//   };
// }

WciApplyPage.propTypes = {
  status: PropTypes.number.isRequired,
  franchisePage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired
};

export default withRouter(WciApplyPage);
